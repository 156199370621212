import * as React from 'react'
import { theme } from '../../theme'

// -- Components --
import { Title } from '../../components/Title'
import { Button } from '../../components'
import * as Hero from '../../components/Hero'
import { LogoGroup, Logo } from '../../components/LogoGroup'
import { Excerpt, ExcerptCopy, ExcerptH3 } from '../../components/Excerpt'
import { BubbleImage } from '../../components/BubbleImage'
import { AwardsAndFeatures } from '../../components/AwardsAndFeatures'
import { BulletList, BulletListItem } from '../../components/BulletList'

// -- Layout --
import { PageLayout } from '../../layouts/PageLayout'
import {
  LargeBullet,
  LargeBulletContent,
  LargeBulletP,
  LargeBulletH4,
  LargeBulletImage,
  LargeBulletH3
} from '../../components/LargeBullet'
import { LargeCallToAction } from '../../modules/LargeCallToAction'

// -- Images --
import backgroundImageSrc from '../../images/hero/hero-grocery.jpg'
import circleIndustryGrocerySrc from '../../images/industries/grocery/img-bubble-shopping.jpg'
import contactUsImgSrc from '../../images/contact-us.png'
import facilitiesSrc from '../../images/industries/grocery/img-bubble-facilities.jpg'
import circleIndustryGroceryOptimiseSrc from '../../images/industries/grocery/img-bubble-refrigerators.jpg'

// Icons
import sustainabilitySrc from '../../images/icons/icon-grocery-sustainability.svg'
import customersSrc from '../../images/icons/icon-grocery-customers.svg'
import foodSrc from '../../images/icons/icon-grocery-food.svg'
import shoppingSrc from '../../images/icons/icon-grocery-shopping.svg'

// Logos
import anuLogoSrc from '../../images/logos/logo-australian-national-university@2x.png'
import pennStateLogoSrc from '../../images/logos/logo-penn-state@2x.png'
import rmitLogoSrc from '../../images/logos/logo-rmit-university@2x.png'
import canberraUniSrc from '../../images/logos/logo-university-of-canberra@2x.png'

const smartBuildingAnalyticsContent = [
  {
    heading: 'Exceed sustainability targets',
    content:
      'Ensure you stay one step ahead of the competition by arming yourself with the insights you need to make smarter decisions and surpass sustainability goals. Respond to climate change by balancing improved indoor environment quality (IEQ) and refrigeration performance with reduced carbon emissions and energy consumption.',
    items: [
      'Enhance ESG credentials that drive corporate brand health',
      'Lower cost of debt that is linked to sustainability targets',
      'Improve investor rating and share price'
    ],
    icon: sustainabilitySrc
  },
  {
    heading: 'Data-led decision making',
    content:
      'Empower your team to make the smartest decisions to optimize your stores’ performance with 24/7 visibility. Monitor hundreds, if not thousands of stores from a central location, with a single source of truth.',
    items: [
      'Flexible and powerful business intelligence',
      'Supercharge your central teams and improve team efficiency',
      'Insights generated in real-time to enable the right corrective actions'
    ],
    icon: customersSrc
  },
  {
    heading: 'Protect food and safety standards',
    content:
      'Safeguard refrigeration equipment and food quality through proactive asset management. Ensure your assets and equipment remain in the best shape.',
    items: [
      'Proactively identify and mitigate potential risks',
      'Reduce stock shrinkage',
      'Track refrigeration performance data',
      'Report on asset compliance'
    ],
    icon: foodSrc
  },
  {
    heading: 'Maximize customer shopping experience',
    content:
      'Bueno Smart Building Analytics system provides your team with the visibility to maximize customer comfort and satisfaction levels and encourage shoppers to spend.',
    items: [
      'Minimize disruptions such as refrigeration or HVAC outages across your stores',
      'Analyze, benchmark and improve indoor environment quality through data-led insights'
    ],
    icon: shoppingSrc
  }
]

const Universities: React.FC = () => {
  return (
    <PageLayout>
      <Title title="Analytics for Grocery & FMCG - Bueno" />

      <Hero.Wrapper src={backgroundImageSrc}>
        <Hero.LayoutContent>
          <Hero.LayoutHeading>
            <Hero.Heading>
              Centralized insights for FMCG & Groceries
            </Hero.Heading>
          </Hero.LayoutHeading>
          <div style={{ marginTop: `${theme.spacing.ultraLoose}` }}>
            <LogoGroup>
              <Logo src={anuLogoSrc} alt="Australian National University" />
              <Logo src={rmitLogoSrc} alt="RMIT University" />
              <Logo src={pennStateLogoSrc} alt="Penn State University" />
              <Logo src={canberraUniSrc} alt="University of Canberra" />
            </LogoGroup>
          </div>
        </Hero.LayoutContent>
      </Hero.Wrapper>

      <div style={{ marginTop: `${theme.spacing.extraLoose}` }}>
        <Excerpt>
          <ExcerptH3>
            The benefits of Bueno’s Smart Building Analytics
          </ExcerptH3>
          <ExcerptCopy>
            Monitor and action large-scale insights from your hundreds, if not
            thousands of stores. Bueno’s Smart Building Analytics solution
            empowers your people to optimize operations and enhance the customer
            experience. All whilst reducing energy consumption, improving asset
            reliability, and exceeding your sustainability goals.
          </ExcerptCopy>
        </Excerpt>
      </div>

      <div style={{ marginTop: '56px' }}>
        <BubbleImage
          src={circleIndustryGrocerySrc}
          imagePosition="right"
          maxHeight={900}
        >
          {smartBuildingAnalyticsContent.map(listItem => (
            <LargeBullet key={listItem.heading}>
              <LargeBulletImage src={listItem.icon} />
              <LargeBulletContent>
                <LargeBulletH4>{listItem.heading}</LargeBulletH4>
                <LargeBulletP>{listItem.content}</LargeBulletP>
                {listItem.items != null && (
                  <BulletList style={{ marginTop: `${theme.spacing.default}` }}>
                    {listItem.items.map(bulletPoint => (
                      <BulletListItem key={bulletPoint}>
                        {bulletPoint}
                      </BulletListItem>
                    ))}
                  </BulletList>
                )}
              </LargeBulletContent>
            </LargeBullet>
          ))}
        </BubbleImage>
      </div>

      <div style={{ marginTop: '56px' }}>
        <BubbleImage
          src={circleIndustryGroceryOptimiseSrc}
          textPosition="center"
          imagePosition="left"
          maxHeight={640}
        >
          <LargeBullet>
            <LargeBulletContent>
              <LargeBulletH3>
                Optimize your stores with Smart Building Analytics
              </LargeBulletH3>
              <div style={{ marginBottom: theme.spacing.default }}>
                <LargeBulletP>
                  Every dollar counts! Reduce energy and operational costs. Gain
                  complete visibility at scale while proactively managing your
                  operations, energy and contractor maintenance. Make smarter
                  decisions that prevent unwanted and costly surprises including
                  after-hours maintenance call-outs that impact your bottom
                  line.
                </LargeBulletP>
              </div>
              <BulletList>
                <BulletListItem>
                  Payback between 12 months and two years
                </BulletListItem>
                <BulletListItem>Energy savings of 10-15%</BulletListItem>
              </BulletList>

              <div style={{ marginTop: `${theme.spacing.ultraLoose}` }}>
                <LargeBulletH4>
                  Take a proactive approach to refrigeration
                </LargeBulletH4>
                <div style={{ marginBottom: theme.spacing.default }}>
                  <LargeBulletP>
                    With 50% of total energies coming from refrigeration, gain
                    real-time insights to optimize your operations and
                    maintenance program before they become energy-wasting
                    problems.
                  </LargeBulletP>
                </div>
                <BulletList>
                  <BulletListItem>
                    Reduce refrigerant leakage by 10-15%
                  </BulletListItem>
                  <BulletListItem>Prevent evaporative ice ups</BulletListItem>
                  <BulletListItem>
                    Optimize refrigeration efficiency
                  </BulletListItem>
                </BulletList>
              </div>
            </LargeBulletContent>
          </LargeBullet>
        </BubbleImage>
      </div>

      <div style={{ marginTop: '56px' }}>
        <BubbleImage
          src={facilitiesSrc}
          textPosition="center"
          imagePosition="right"
          maxHeight={640}
        >
          <LargeBullet>
            <LargeBulletContent>
              <LargeBulletH3>
                Facility management & asset optimization
              </LargeBulletH3>
              <div style={{ marginBottom: theme.spacing.default }}>
                <LargeBulletP>
                  Identify and mitigate store and equipment inefficiencies
                  through proactive asset management. Collect and monitor large
                  data sets with ease, from HVAC/R, fire systems and water
                  treatment.
                </LargeBulletP>
              </div>
              <BulletList>
                <BulletListItem>Identify root cause diagnostics</BulletListItem>
                <BulletListItem>
                  Benchmark and measure asset and portfolio performance
                </BulletListItem>
                <BulletListItem>
                  Improve asset reliability and extend life cycle of equipment
                </BulletListItem>
                <BulletListItem>
                  Manage end-to-end warranty lifecycle
                </BulletListItem>
              </BulletList>

              <div style={{ marginTop: `${theme.spacing.ultraLoose}` }}>
                <LargeBulletH4>
                  Take a proactive approach to contractor management
                </LargeBulletH4>
                <div style={{ marginBottom: theme.spacing.default }}>
                  <LargeBulletP>
                    Bueno’s Smart Building Analytics Solution allows your team
                    to work in collaboration with contractors to optimize asset
                    and operational performance. Manage and benchmark service
                    delivery to ensure SLA’s are upheld.
                  </LargeBulletP>
                </div>
                <BulletList>
                  <BulletListItem>
                    Optimize maintenance schedules{' '}
                  </BulletListItem>
                  <BulletListItem>
                    Prioritize asset management & maintenance
                  </BulletListItem>
                  <BulletListItem>
                    Benchmark and measure maintenance performance
                  </BulletListItem>
                  <BulletListItem>Minimize reactive maintenance</BulletListItem>
                </BulletList>
              </div>
            </LargeBulletContent>
          </LargeBullet>
        </BubbleImage>
      </div>

      <div style={{ marginTop: '56px' }}>
        <LargeCallToAction
          title="Trusted & passionate team of experts"
          body="We have the most experienced and trusted team coupled with market-leading software to support your building optimization journey. Learn more about the product features and functionality and services."
          image={contactUsImgSrc}
          callToAction={<Button to="/contact">Talk to an expert</Button>}
        />
      </div>

      <div style={{ marginTop: '112px' }}>
        <AwardsAndFeatures />
      </div>
    </PageLayout>
  )
}

export default Universities
